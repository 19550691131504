<script setup>
useHead({
    bodyAttrs: {
        class: 'font-sans antialiased bg-white',
    },
});
</script>

<template>
    <div>
        <main>
            <slot />
        </main>
    </div>
</template>
